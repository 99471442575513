import { Grid, Icon, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';

import vid1 from '../videos/vid1.mp4';
import vid2 from '../videos/vid2.mp4';
import vid3 from '../videos/vid3.mp4';
import vid4 from '../videos/vid4.mp4';

import Logo from '../images/logo.jpeg';
/*<video controls>
< source src={vid1} type="video/mp4" />
Your browser does not support the video tag.
</video>*/

const Hearings = () => {

    return (

        <div style={{paddingBottom: "4rem"}}>
            <Typography style={{ paddingTop: '5vh', display: 'flex', justifyContent: 'center' }}
                variant="h3"
                component="div"
                sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
                Hörproben
            </Typography>
            <Grid container xl={12} xs={12}>
                <Grid 
                    xs={12} 
                    xl={3} 
                    item 
                    style={{
                        display:"flex", 
                        flexDirection: "column", 
                        alignItems: "center", 
                        justifyContent: "center", 
                        width: "100px", 
                        padding: "30px" 
                    }}
                >
                    <img width="50%" src={Logo}></img>
                    <audio style={{margin: "20px"}} controls src={vid1}>
                    </audio>
                </Grid>
                <Grid 
                    xs={12} 
                    xl={3} 
                    item 
                    style={{
                        display:"flex", 
                        flexDirection: "column", 
                        alignItems: "center", 
                        justifyContent: "center", 
                        width: "100px", 
                        padding: "30px" 
                    }}
                >
                    <img width="50%" src={Logo}></img>
                    <audio style={{margin: "20px"}} controls src={vid2}>
                    </audio>
                </Grid>
                <Grid 
                    xs={12} 
                    xl={3} 
                    item 
                    style={{
                        display:"flex", 
                        flexDirection: "column", 
                        alignItems: "center", 
                        justifyContent: "center", 
                        width: "100px", 
                        padding: "30px" 
                    }}
                >
                    <img width="50%" src={Logo}></img>
                    <audio style={{margin: "20px"}} controls src={vid3}>
                    </audio>
                </Grid>
                <Grid 
                    xs={12} 
                    xl={3} 
                    item 
                    style={{
                        display:"flex", 
                        flexDirection: "column", 
                        alignItems: "center", 
                        justifyContent: "center", 
                        width: "100px", 
                        padding: "30px" 
                    }}
                >
                    <img width="50%" src={Logo}></img>
                    <audio style={{margin: "20px"}} controls src={vid4}>
                    </audio>
                </Grid>
            </Grid>
        </div>


    );
};

export default Hearings;