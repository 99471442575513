//components
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import './App.css';
import React, { useState } from 'react';

//custom pages
import Slideshow from './components/Slideshow'

//theming
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';

//pages
import Contact from './pages/Contact';
import Footer from './pages/Footer';

import About from './pages/About';
import Hearings from './pages/Hearings';

import Songs from './pages/Songs';

import { mobileCheck } from './util/mobileCheck';
import Navigation from './pages/Navigation';

import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import { Link, Typography } from "@mui/material";


const desktop = !mobileCheck();

const theme = createTheme({
  typography: {
    fontFamily: [
      'Dancing Script',
    ].join(','),
    button: {
      textTransform: 'none' //prevent button from displaying text in upper case
    }
  }
});

export default function DrawerAppBar() {
  const [open, setOpen] = useState(true);
  const [cookiesAccepted, setCookiesAccepted] = useState(true);

  const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    setCookiesAccepted(true);
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(false);
    setCookiesAccepted(true);
  };

  const handleDeny = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(false);
    setCookiesAccepted(false);
  };

  return (
    <div>
      <ThemeProvider theme={theme} >
        <Navigation />
        <Box sx={{ overflowX: 'hidden', display: 'flex' }}>
          <CssBaseline />
          <Box component="main" sx={{ overflow: 'hidden' }}>
            <div id="home">
              <Slideshow></Slideshow>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', left: '0', bottom: '0', height: '20vh', width: '100%', backgroundColor: 'white' }}>
                <p style={{ fontSize: desktop ? '2rem' : '1.5rem', textAlign: 'center' }}>Wir sind Marie und Anne. Wir singen mit Klavierbegleitung für Ihre Hochzeit, Taufe oder andere Feierlichkeiten.</p>
              </div>
            </div>
            <div id="about"
              style={{
                backgroundColor: '#CFF0CC',
                display: 'flex'
              }}>

              <About />

            </div>

            <div id="hearing">
              <Hearings />
            </div>

            <div id="songs">



              {cookiesAccepted && (
                <Songs />
              )}

              {
                !cookiesAccepted && (<div>
                  <Typography style={{ paddingTop: '5vh', display: 'flex', justifyContent: 'center' }}
                    variant="h3"
                    component="div"
                    sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                  >
                    Auswahl an Liedern
                  </Typography>

                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', left: '0', bottom: '0', height: '20vh', width: '100%', backgroundColor: 'white' }}>
                    <p style={{ fontSize: desktop ? '2rem' : '1.5rem', textAlign: 'center' }}>Wir verwenden Cookies, um Ihre Erfahrung zu verbessern. Bitte bestätigen Sie die Cookies zur Anzeige der Auswahl an Liedern: <Button size="large" onClick={handleButtonClick}>
              AKZEPTIEREN
            </Button></p>
                    <br/>
                    <Button color="primary" size="small" onClick={handleButtonClick}>
                    
                  </Button>
                  </div>

                </div>

                )}

            </div>

            <div id="contact" style={{ background: 'white' }}>
              <Contact />
              <Footer />
            </div>

          </Box>
        </Box>
      </ThemeProvider>
      <Snackbar
        open={open}
        onClose={handleSnackbarClose}
        message="Wir verwenden Cookies, um Ihre Erfahrung zu verbessern."
        action={
          <React.Fragment>
            <Button color="primary" size="small" onClick={handleButtonClick}>
              AKZEPTIEREN
            </Button>

            <Button color="primary" size="small" onClick={handleDeny}>
              ABLEHNEN
            </Button>
          </React.Fragment>
        } />
    </div>
  );

}