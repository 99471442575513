import { Snackbar, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import emailjs from 'emailjs-com';

const FORM_ENDPOINT = "https://herotofu.com/start"; // TODO - update to the correct endpoint

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [open, setOpen] = useState(false);
  if (submitted) {
    return (
        <div className="contact-form-wrapper d-flex justify-content-center">
          <>
            <h1>Danke für die Nachricht! Wir werden uns in Kürze mit Ihnen in Verbindung setzen.</h1>
          </>
        </div>
    );
  }

  const sendEmail = (e: any) => {
    e.preventDefault();
  
    emailjs.sendForm('service_ssygabb', 'template_le058w7', e.target, 'ogY-QfCynvRfLzzDa')
      .then((result) => {
          setSubmitted(true);
          setOpen(true);
      }, (error) => {
          console.log(error.text);
      });
  }

  /*
  <TextField sx={{color: 'white', width: '30vh'}} placeholder= "Vorname" label="Vorname" focused /><br/><br/>
  <TextField sx={{width: '30vh'}} placeholder= "Nachname" label="Nachname"  focused /><br/><br/>
  <TextField sx={{width: '30vh'}} placeholder= "E-Mail" label="E-Mail"  focused /><br/><br/>
  <TextField sx={{width: '30vh'}} placeholder= "Nachricht" label="Nachricht"focused />
  */

  /*
  rosa: #f5e0e5
  minzgrün: #CFF0CC
  */
  return (

    <div style={{ backgroundColor: '#CFF0CC', width: '100vw', textAlign: 'center' }}>
      <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
        <Typography fontSize="1.75rem" style={{ paddingTop: '5vh', width: "50%"}} variant="h3">
        Gerne besprechen wir mit Ihnen gemeinsam, welche Lieder wir für Sie spielen dürfen.
        </Typography>
      </div>
    <div style={{ backgroundColor: '#CFF0CC', width: '100vw', textAlign: 'center' }}>
    </div>
      <div>
        <div className="contact-form-wrapper d-flex justify-content-center">
          <form action="#" className="contact-form" onSubmit={sendEmail}>
            <h5 className="title">Kontaktaufnahme</h5>
            <p style={{ fontSize: '1.5rem' }} className="description">Schreiben Sie uns gerne eine Nachricht
            </p>
            <div>
              <input style={{ fontSize: '1.5rem' }} type="text" name="name" className="form-control rounded border-white mb-3 form-input" id="name" placeholder="Name" required />
            </div>
            <div>
              <input style={{ fontSize: '1.5rem' }} type="email" id="email" name="email" className="form-control rounded border-white mb-3 form-input" placeholder="Email" required />
            </div>
            <div>
              <textarea style={{ fontSize: '1.5rem' }} id="message" name="message" className="form-control rounded border-white mb-3 form-text-area" rows={5} cols={30} placeholder="Nachricht" required></textarea>
            </div>
            <div className="submit-button-wrapper">
              <input style={{ fontSize: '1.5rem' }} type="submit" value="Senden" />
            </div>
          </form>
        </div>
      </div>
    </div>

  );
};

export default ContactForm;