import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';
import { mobileCheck } from './util/mobileCheck';
import Datenschutz from './pages/Datenschutz';
import Impressum from './pages/Impressum';
import Navigation from './pages/Navigation';

import {
  createHashRouter,
  RouterProvider
} from 'react-router-dom';

const router = createHashRouter([
  {
    path: "/*",
    element: <App />,
  },
  {
    path: "/datenschutz",
    element: <Datenschutz />,
  },
  {
    path: "/impressum",
    element: <Impressum />,
  }

]);

const desktop = !mobileCheck();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <div>
    <RouterProvider router={router} />
  </div>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
