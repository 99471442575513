import { Link } from "@mui/material";
import Navigation from "./Navigation";

const Datenschutz = () => {
    return (
        <div>
            <Navigation onlyHome={true} />
            <div style={{ marginTop: "10vh", paddingLeft: "2rem", paddingRight: "2rem" }}>
                <h5>Datenschutzerklärung für Marie und Anne</h5><br />

                Diese Datenschutzerklärung informiert Sie über die Art, den Umfang und den Zweck der Verarbeitung personenbezogener Daten (nachfolgend "Daten") im Rahmen unserer Website und unseres Geschäftsbetriebs.
                <br /><br />
                <h5>Verantwortliche Stelle:</h5>
                <br />
                Marie und Anne
                <br />
{/*

                [Name des Unternehmens]<br />
                [Adresse des Unternehmens]<br />
                [Kontaktinformationen des Unternehmens]<br />

*/}


                <br />

                <h5> Arten der verarbeiteten Daten:</h5>

                <br />

                Personenbezogene Daten (z. B. Name, Adresse, E-Mail-Adresse, Telefonnummer)<br />
                Nutzungsdaten (z. B. besuchte Seiten, Klickverhalten, IP-Adresse)<br />
                Kommunikationsdaten (z. B. E-Mail-Inhalte, Nachrichtenverlauf)<br />

                <br />
                <h5>Zwecke der Datenverarbeitung:</h5><br />

                Wir verarbeiten Ihre Daten für folgende Zwecke:

                <br /><br />

                Zur Durchführung und Abwicklung von Buchungen und Verträgen<br />
                Zur Beantwortung von Anfragen und zur Kommunikation mit Kunden<br />
                Zur Verbesserung unseres Service und unserer Website<br />
                Zur Erfüllung rechtlicher Verpflichtungen<br /><br />


                <h5>Verwendung von Bildern von Pixabay:</h5><br />

                Unsere Website verwendet Bilder, die wir von der Plattform Pixabay beziehen. Pixabay ist eine Online-Plattform, die kostenlose und lizenzfreie Bilder anbietet.

                Beim Einbetten von Bildern von Drittanbietern, wie Pixabay, kann es dazu kommen, dass personenbezogene Daten, insbesondere Ihre IP-Adresse, an den Drittanbieter übermittelt werden. Diese Übermittlung ist technisch notwendig, um die Bilder in Ihrem Browser anzeigen zu können.

                Wir haben keinen Einfluss darauf, welche Daten durch Pixabay tatsächlich erhoben und verarbeitet werden. Weitere Informationen zur Datenverarbeitung durch Pixabay finden Sie in der <Link href={"https://pixabay.com/de/service/privacy/"} style={{ paddingRight: "10px" }} color="inherit">Datenschutzerklärung von Pixabay.</Link>

                Die Einbindung der Bilder erfolgt auf Grundlage unseres berechtigten Interesses gemäß Art. 6 Abs. 1 lit. f DSGVO, um unsere Website ansprechend und informativ zu gestalten.

                Falls Sie Fragen zur Verarbeitung Ihrer personenbezogenen Daten haben, können Sie sich jederzeit an uns wenden.


                <br/><br/>


                <h5>Einbindung von Spotify iFrames:</h5><br />

Unsere Website nutzt iFrames von Spotify, um Musik und andere Inhalte direkt abzuspielen. Durch die Einbindung dieser iFrames wird eine direkte Verbindung zu den Servern von Spotify hergestellt. Dabei werden personenbezogene Daten, wie Ihre IP-Adresse, an Spotify übermittelt.

Spotify kann zudem Cookies oder andere Tracking-Technologien verwenden, um Informationen über Ihre Nutzung der eingebetteten Inhalte zu sammeln, unabhängig davon, ob Sie ein Spotify-Konto besitzen oder nicht.

Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO, die Sie durch die Nutzung unserer Website und das Akzeptieren von Cookies erteilen.

Weitere Informationen zur Datenverarbeitung und den Datenschutzbestimmungen von Spotify finden Sie in der <Link href={"https://www.spotify.com/de/legal/privacy-policy/"} style={{ paddingRight: "10px" }} color="inherit">Datenschutzerklärung von Spotify.</Link>

Sie können Ihre Einwilligung zur Datenverarbeitung jederzeit widerrufen, indem Sie Ihre Cookie-Einstellungen anpassen oder die iFrames deaktivieren.

<br/><br/>
                <h5>Rechtsgrundlagen der Verarbeitung:</h5>

                <br />
                Wir verarbeiten Ihre Daten auf Grundlage der folgenden Rechtsgrundlagen:

                <br /><br />

                Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO
                Vertragserfüllung gemäß Art. 6 Abs. 1 lit. b DSGVO
                Erfüllung rechtlicher Verpflichtungen gemäß Art. 6 Abs. 1 lit. c DSGVO
                Berechtigtes Interesse gemäß Art. 6 Abs. 1 lit. f DSGVO
                Weitergabe von Daten:

                Wir geben Ihre Daten nur weiter, wenn dies zur Erfüllung unserer vertraglichen Pflichten erforderlich ist, auf Grundlage Ihrer Einwilligung oder wenn eine rechtliche Verpflichtung besteht.

                Speicherdauer:

                Wir speichern Ihre Daten nur solange, wie es für die Zwecke, für die sie erhoben wurden, erforderlich ist, oder solange gesetzliche Aufbewahrungsfristen bestehen.

                Ihre Rechte:

                Sie haben das Recht auf Auskunft, Berichtigung, Löschung, Einschränkung der Verarbeitung und Datenübertragbarkeit Ihrer Daten. Sie haben auch das Recht, der Verarbeitung Ihrer Daten zu widersprechen. Bitte kontaktieren Sie uns dazu unter den unten angegebenen Kontaktdaten.

                Kontakt:

                <br /><br />
{/*

                Bei Fragen zur Verarbeitung Ihrer Daten oder zur Ausübung Ihrer Rechte können Sie sich an uns wenden:

                <br /><br />

                [Name des Unternehmens]<br />
                [Adresse des Unternehmens]<br />
                [Kontaktinformationen des Unternehmens]<br />

*/}

            </div>
        </div>

    );
};

export default Datenschutz;